<script>
import { defineComponent } from "vue";
import AppDrawer from "@/components/Ui/AppDrawer.vue";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
  name: "CourseActionsDrawer",

  components: { AppDrawer },

  props: {
    actions: {
      type: Array,
    },
  },

  emits: ["close", "action"],

  computed: {
    ...mapState({
      course: (state) => state.course.course,
    }),

    ...mapGetters({
      courseChangesAvailable: "course/courseChangesAvailable",
    }),
  },

  methods: {
    courseAction(action) {
      this.$emit("action", action);
      this.$emit("close");
    },
  },
});
</script>

<template>
  <AppDrawer @close="$emit('close')" :title="course.name">
    <div class="drawer__content">
      <button @click="courseAction('preview')">
        <i class="icon icon-preview" /> Предпросмотр
      </button>

      <button @click="courseAction('publish')" v-if="courseChangesAvailable">
        <i class="icon icon-publish" />
        Опубликовать
      </button>

      <button
        v-for="action in actions"
        :key="action.id"
        @click="courseAction(action.id)"
        :class="action.class"
      >
        <i class="icon" :class="`icon-${action.icon}`" /> {{ action.name }}
      </button>
    </div>

    <div class="drawer__actions">
      <button
        class="button button_secondary button_large"
        @click="$emit('close')"
      >
        Отмена
      </button>
    </div>
  </AppDrawer>
</template>

<style scoped lang="scss"></style>

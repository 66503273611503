<script>
import { mapState } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MobileMenu",

  computed: {
    ...mapState({
      windowWidth: (state) => state.system.window_width,
    }),

    partnerLink() {
      if (process.env.NODE_ENV === "development") {
        return process.env.VUE_APP_STUDENT_RU_URL;
      }

      if (window.location.host === "coorser.com") {
        return process.env.VUE_APP_STUDENT_COM_URL;
      }

      return process.env.VUE_APP_STUDENT_RU_URL;
    },
  },

  watch: {
    $route() {
      this.$emit("close");
    },

    windowWidth(newValue) {
      if (newValue > 1023) {
        this.$emit("close");
      }
    },
  },
});
</script>

<template>
  <aside class="mobile-menu">
    <div class="mobile-menu__wrapper">
      <header>
        <img src="../assets/images/logo.svg" alt="logotype" />

        <button @click="$emit('close')">
          <i class="icon icon-close" />
        </button>
      </header>

      <nav>
        <a :href="partnerLink" target="_blank">Для учеников</a>
      </nav>

      <div class="mobile-menu__support">
        <p>Техническая поддержка</p>
        <a href="mailto:help@coorser.com">
          <i class="icon icon-mail" />help@coorser.com
        </a>
      </div>

      <router-link :to="{ name: 'AuthView' }" class="button button_large">
        Вход / Регистрация
      </router-link>
    </div>
  </aside>
</template>

<style scoped lang="scss">
.mobile-menu {
  z-index: 50;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: $background-light;

  &__wrapper {
    padding: ($base-space * 6) ($base-space * 6) 80px ($base-space * 6);
    position: relative;
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > img {
        width: 96px;
      }

      > button {
        width: 36px;
        height: 36px;

        > i {
          width: 36px;
          height: 36px;
          background: $text-dark-third;
        }
      }
    }

    > nav {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      grid-gap: $base-space * 6;
      margin: ($base-space * 15) 0 0;

      > a {
        @include xl-text-regular();
        color: $text-dark-second;

        &.router-link-active {
          color: $text-accent;
        }
      }
    }

    > .button {
      width: calc(100% - 48px);
      position: fixed;
      bottom: 24px;
      left: 24px;
      right: 0;
    }
  }

  &__support {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: $base-space * 2;
    margin: ($base-space * 15) 0 0;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $base-space * 2;
      color: $text-dark-second;
      @include l-text-regular();

      > i {
        width: 24px;
        height: 24px;
        background: $text-dark-second;
      }
    }
  }
}
</style>

import API from "@/api";

const state = {};

const mutations = {};

const actions = {
  loadCourseStatistic(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data}/units/statistics`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCourseTasks(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data}/units/tasks`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUnitTasks(_, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/partners/courses/${data.course_id}/units/${data.unit_id}/tasks`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadReviewedTasks(_, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/partners/courses/${data.course_id}/units/${data.unit_id}/tasks/reviewed`,
        { params: data.params }
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUnReviewedTasks(_, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/partners/courses/${data.course_id}/units/${data.unit_id}/tasks/unreviewed`,
        { params: data.params }
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUserTask(_, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/partners/courses/${data.course_id}/units/${data.unit_id}/tasks/user/${data.user_id}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markUserTask(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `api/partners/courses/${data.course_id}/units/${data.unit_id}/tasks/${data.task_id}`,
        data.data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

import {
  COURSE_STATUSES,
  LESSON_TYPES,
  TASK_STATUSES,
} from "@/package/const/statuses";

export function getCourseStatusInfo(statusId) {
  return COURSE_STATUSES.find((status) => status.id === statusId);
}

export function getLessonTypeInfo(lessonType) {
  return LESSON_TYPES.find((type) => type.id === lessonType);
}

export function getTaskStatus(status) {
  return TASK_STATUSES.find((type) => type.id === status);
}

<script>
import { defineComponent } from "vue";
import AppModal from "@/components/Ui/AppModal.vue";
import AppInput from "@/components/Ui/AppInput.vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, maxLength, required } from "@vuelidate/validators";
import { mapActions, mapMutations } from "vuex";

export default defineComponent({
  name: "ComponentRenameModal",

  components: { AppInput, AppModal },

  props: {
    component: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      default: "component",
    },
  },

  emits: ["close"],

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      name: "",

      button_loader: false,
    };
  },

  validations() {
    return {
      name: {
        required: helpers.withMessage("Заполните поле", required),
        maxLength: helpers.withMessage(
          "Превышен лимит символов",
          maxLength(150)
        ),
      },
    };
  },

  methods: {
    ...mapActions({
      editCourseUnit: "course/editCourseUnit",
    }),

    ...mapMutations({
      setNotification: "system/SET_NOTIFICATION",
    }),

    submitForm() {
      this.v$.$validate();

      if (!this.button_loader && !this.v$.$error) {
        this.button_loader = true;

        this.editCourseUnit({
          course_id: this.$route.params.id,
          id: this.component.id,
          data: {
            name: this.name,
          },
        })
          .then(() => {
            this.setNotification({
              type: "success",
              name: "Данные сохранены",
            });
            this.$emit("close");
          })
          .catch((error) => {
            this.setNotification({
              type: "error",
              name: "Что-то пошло не так",
              description: error.response.data.message,
            });
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },
  },

  beforeMount() {
    this.name = structuredClone(this.component.name);
  },
});
</script>

<template>
  <AppModal @close="$emit('close')" size="medium">
    <div class="default-modal edit-component">
      <h3>Переименовать {{ type === "component" ? "компонент" : "модуль" }}</h3>

      <form @submit.prevent="submitForm">
        <AppInput
          id="name"
          :label="`Название ${type === 'component' ? 'компонента' : 'модуля'}`"
          required
          :maxlength="150"
          v-model="name"
          :error="v$.name?.$errors[0]?.$message"
        />

        <div class="edit-component__actions">
          <button
            class="button button_large"
            type="submit"
            :disabled="button_loader"
          >
            Сохранить
          </button>

          <button
            type="button"
            class="button button_secondary button_large"
            @click="$emit('close')"
          >
            Отмена
          </button>
        </div>
      </form>
    </div>
  </AppModal>
</template>

<style scoped lang="scss">
.edit-component {
  width: 794px;

  @media (max-width: 1023px) {
    width: 100%;
    overflow: auto;
    max-height: 82vh;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  > form {
    margin: ($base-space * 8) 0 0;

    @media (max-width: 1023px) {
      margin: ($base-space * 6) 0 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $base-space * 3;
    margin: ($base-space * 8) 0 0;

    @media (max-width: 1023px) {
      margin: ($base-space * 6) 0 0;
    }

    @media (max-width: 767px) {
      flex-flow: column;
      align-items: flex-start;
      gap: $base-space * 2;

      > button {
        width: 100%;
      }
    }
  }
}
</style>

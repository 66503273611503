import API from "@/api";

const state = { categories: [] };

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
};

const actions = {
  loadCourseQuestionsCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data}/questions/categories`)
        .then((response) => {
          commit("SET_CATEGORIES", response.data.categories);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadCourseQuestionsList(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data.course_id}/questions`, {
        params: data.params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createCourseQuestion(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/courses/${data.course_id}/questions`, data.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateCourseQuestion(_, data) {
    return new Promise((resolve, reject) => {
      API.put(
        `api/partners/courses/${data.course_id}/questions/${data.question_id}`,
        data.data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteCourseQuestion(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(
        `api/partners/courses/${data.course_id}/questions/${data.question_id}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadCourseQuestionInfo(_, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/partners/courses/${data.course_id}/questions/${data.question_id}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

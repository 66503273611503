<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import CourseComponent from "@/components/Course/CourseComponent.vue";
import CourseModule from "@/components/Course/CourseModule.vue";
import wordDeclension from "../../package/helpers/wordDeclension";

export default defineComponent({
  name: "CoursePagePreview",

  components: { CourseModule, CourseComponent },

  props: {
    lessons: {
      type: Number,
    },
  },

  emits: ["close"],

  computed: {
    ...mapState({
      course: (state) => state.course.course,
      modules: (state) => state.course.modules,
      user: (state) => state.user.user,
    }),

    lessonsCount() {
      let counter = 0;

      this.modules
        .filter((module) => module.children && module.children.length > 0)
        .forEach(
          (item) =>
            (counter += item.children.filter(
              (module) => module.type === "lesson"
            ).length)
        );

      return (
        this.modules.filter((module) => module.type === "lesson").length +
        counter
      );
    },
  },

  methods: { wordDeclension },

  mounted() {
    let body = document.querySelector("body");

    body.style.overflow = "hidden";
    body.style.position = "fixed";
    body.style.width = "100%";
    body.style.height = "100vh";
  },

  beforeUnmount() {
    let body = document.querySelector("body");

    body.style.removeProperty("overflow");
    body.style.removeProperty("position");
    body.style.removeProperty("width");
    body.style.removeProperty("height");
  },
});
</script>

<template>
  <div class="course-preview">
    <div class="course-preview__header">
      <h3>Режим предпросмотра</h3>
      <button
        class="button button_secondary button_medium"
        @click="$emit('close')"
      >
        Вернуться к редактированию
      </button>
    </div>

    <div class="course-preview__content">
      <div class="course-header">
        <div
          class="course-header__cover"
          :style="{
            backgroundImage: `url(${
              course?.media?.cover ? course?.media?.cover.url.full_size : ''
            })`,
          }"
        >
          <i class="icon icon-image" v-if="!course.media?.cover" />
        </div>

        <div class="container">
          <div class="course-header__info">
            <h1>{{ course.name }}</h1>

            <span class="label">{{ course.category.name }}</span>
          </div>
        </div>
      </div>

      <main class="course-info">
        <div class="container">
          <section>
            <h2>О курсе</h2>

            <p v-if="course.description">{{ course.description }}</p>
            <span v-else>Описание не добавлено</span>
          </section>

          <aside>
            <div class="course-info__card card">
              <div class="card__info">
                <p>
                  <i class="icon icon-person" />Автор:
                  <span>
                    {{ `${user.first_name} ${user.last_name}` }}
                  </span>
                </p>

                <p>
                  <i class="icon icon-play-circle" />
                  {{
                    `${lessonsCount} ${wordDeclension(lessonsCount, [
                      "Урок",
                      "Урока",
                      "Уроков",
                    ])}`
                  }}
                </p>

                <div class="card__reviews">
                  <p class="rating" v-if="course.rating">
                    <i class="icon icon-star-fill" /> {{ course.rating }}
                  </p>

                  <p class="reviews">
                    <i class="icon icon-speach" />
                    {{ course.reviews ? course.reviews : "Нет отзывов" }}

                    <span v-if="course.reviews">отзывов</span>
                  </p>
                </div>
              </div>

              <div v-if="!course.is_participated" class="card__price">
                Бесплатно
              </div>

              <div class="card__actions">
                <button
                  v-if="!course.is_participated"
                  class="button button_large"
                >
                  Записаться на курс
                </button>
              </div>
            </div>
          </aside>
        </div>
      </main>

      <section class="course-plan plan">
        <div class="container">
          <h2>План курса</h2>

          <div class="plan__list">
            <template v-for="(item, index) in modules" :key="index">
              <CourseModule
                :module="item"
                :parent-index="index + 1"
                v-if="item.type === 'module'"
                type="preview"
              />

              <CourseComponent
                :component="item"
                :index="index + 1"
                type="preview"
                v-else
              />
            </template>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
.course-preview {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: $background-light;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ($base-space * 4);
    background-color: $background-light;

    @media (max-width: 1023px) {
      padding: ($base-space * 3) ($base-space * 8);
    }

    @media (max-width: 767px) {
      padding: ($base-space * 3) ($base-space * 6);
    }

    @media (max-width: 424px) {
      padding: ($base-space * 3) ($base-space * 5);
    }

    @media (max-width: 374px) {
      padding: ($base-space * 3) ($base-space * 4);
    }

    > h3 {
      @include m-text-regular();
      color: $text-dark-primary;

      @media (max-width: 460px) {
        display: none;
      }
    }
  }

  &__content {
    height: calc(100vh - 80px);
    width: 100%;
    background-color: $background-light;
    overflow: auto;

    @media (max-width: 1023px) {
      height: calc(100vh - 64px);
    }
  }
}

.course {
  &-header {
    height: 360px;
    width: 100%;
    position: relative;

    > .container {
      padding: ($base-space * 8) 0 ($base-space * 12);
      display: flex;
      flex-flow: column;
      justify-content: flex-end;
      align-items: flex-start;
      height: 100%;
      position: relative;
      z-index: 2;

      @media (max-width: 1023px) {
        padding: ($base-space * 6) 0 ($base-space * 20);
      }
    }

    &__cover {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      &:after {
        content: "";
        position: absolute;
        background-color: rgba(0, 6, 34, 0.6);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      > i {
        width: 160px;
        height: 160px;
        background-color: $text-dark-placeholder;
      }
    }

    &__info {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: $base-space * 8;

      @media (max-width: 1023px) {
        gap: $base-space * 4;
      }

      > h1 {
        @include h2();
        color: $text-light-primary;
        max-width: 720px;

        @media (max-width: 1439px) {
          max-width: 692px;
        }

        @media (max-width: 1239px) {
          max-width: 500px;
        }
      }

      > span {
        color: $text-light-primary;
        background: none;
        border: 1px solid $stroke-light;
      }
    }
  }

  &-info {
    background-color: $background-light;

    > .container {
      padding: ($base-space * 18) 0 ($base-space * 30);
      display: grid;
      grid-template-columns: 720px 1fr;
      align-items: end;
      grid-gap: ($base-space * 30);

      @media (max-width: 1439px) {
        grid-template-columns: 692px 1fr;
        grid-gap: ($base-space * 18);
      }

      @media (max-width: 1239px) {
        grid-template-columns: 500px 1fr;
        grid-gap: ($base-space * 12);
      }

      @media (max-width: 1023px) {
        display: flex;
        flex-flow: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        gap: ($base-space * 12);
        padding: 0 0 ($base-space * 15);
      }

      > section {
        min-height: 200px;
        @media (max-width: 1023px) {
          min-height: auto;
        }
        > h2 {
          @include h4();
          color: $text-dark-second;
        }

        > p {
          @include l-text-regular();
          color: $text-dark-second;
          margin: ($base-space * 6) 0 0;
          white-space: pre-wrap;
        }

        > span {
          color: $text-dark-third;
          @include l-text-regular();
          display: block;
          margin: ($base-space * 6) 0 0;
        }
      }

      > aside {
        position: relative;

        @media (max-width: 1023px) {
          width: 100%;
        }
      }
    }
  }
}

.card {
  background-color: $background-light;
  padding: ($base-space * 12);
  @include main-shadow();
  border-radius: 16px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;

  @media (max-width: 1023px) {
    position: static;
    width: 100%;
    margin: (-$base-space * 12) 0 0;
    padding: ($base-space * 6);
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr;
    gap: $base-space * 5;

    @media (max-width: 1023px) {
      gap: $base-space * 4;
    }

    > p {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: start;
      align-items: center;
      gap: $base-space * 2;
      color: $text-dark-second;
      @include m-text-regular();

      > i {
        width: 24px;
        height: 24px;
        background-color: $text-accent;
      }

      > span {
        @include max-stroke(1);
      }
    }
  }

  &__reviews {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $base-space * 3;

    > p {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space;
      @include m-text-regular();

      > i {
        width: 20px;
        height: 20px;
      }

      &.rating {
        > i {
          background: $text-rating;
        }
      }

      &.reviews {
        color: $text-dark-third;

        > i {
          background: $text-dark-third;
        }
      }
    }
  }

  &__price {
    margin: ($base-space * 6) 0 0;
    padding: ($base-space * 6) 0 0;
    border-top: 1px solid $stroke-fourth;
    @include h4();
    color: $text-accent-dark;

    @media (max-width: 1023px) {
      margin: ($base-space * 4) 0 0;
      padding: ($base-space * 4) 0 0;
    }
  }

  &__actions {
    margin: ($base-space * 10) 0 0;

    @media (max-width: 1023px) {
      margin: ($base-space * 6) 0 0;
    }

    > button {
      width: 100%;
    }
  }
}

.plan {
  background-color: $background-ultralight-gray;
  padding: ($base-space * 30) 0;

  @media (max-width: 1023px) {
    padding: ($base-space * 15) 0;
  }

  > .container {
    > h2 {
      color: $text-dark-primary;
      @include h2();
    }
  }

  &__list {
    margin: ($base-space * 12) 0 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $base-space * 4;

    @media (max-width: 767px) {
      margin: ($base-space * 8) (-$base-space * 4) 0;
    }

    @media (max-width: 374px) {
      margin: ($base-space * 8) (-$base-space * 3) 0;
    }
  }
}
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppTextarea",

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },

    id: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "text",
    },

    error: {
      type: String,
      default: "",
    },

    hideError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
    },

    maxlength: {
      type: Number,
    },

    required: {
      type: Boolean,
    },

    notRequired: {
      type: Boolean,
    },
  },

  methods: {
    updateTextareaValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
});
</script>

<template>
  <div class="app-textarea">
    <div class="app-textarea__header" v-if="label">
      <label v-if="label" :for="id">
        {{ label }}
        <span class="app-textarea_required" v-if="required">*</span>
        <span class="app-textarea_not-required" v-if="notRequired">
          - Необязательно
        </span>
      </label>

      <span class="app-textarea__max-length" v-if="maxlength">
        {{ modelValue.length }}/{{ maxlength }}
      </span>
    </div>

    <div class="textarea" :class="{ textarea_error: error }">
      <textarea
        :id="id"
        :value="modelValue"
        @input="updateTextareaValue"
        :disabled="disabled"
        :maxlength="maxlength"
      />
    </div>

    <span class="error" v-if="error && !hideError">
      {{ error }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.app-textarea {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  gap: $base-space * 2;
  width: 100%;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > label {
      color: $text-dark-second;
      @include s-text-regular();
      padding: 0 0 0 ($base-space);
    }
  }

  &_required {
    @include s-text-regular();
    color: $text-error;
  }

  &_not-required {
    @include s-text-regular();
    color: $text-fifth;
  }

  > .textarea {
    width: 100%;

    > textarea {
      width: 100%;
      height: 150px;
      padding: ($base-space * 4) ($base-space * 5);
      resize: none;
      @include m-text-regular();
      color: $text-dark-primary;
      background: none;
      outline: none;
      border: 1px solid $stroke-second;
      border-radius: 8px;
      padding: ($base-space * 4);

      &::placeholder {
        color: $text-dark-placeholder;
      }

      &:focus {
        outline: none;
        border-color: $stroke-accent;
      }

      &::placeholder {
        color: $text-dark-placeholder;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    &_error {
      > textarea {
        border-color: $stroke-error;
      }
    }
  }

  &__max-length {
    @include s-text-regular();
    color: $text-dark-second;
  }
}
</style>

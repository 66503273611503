<script>
import SupportModal from "@/components/SupportModal.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import { mapState } from "vuex";
import { defineComponent } from "vue";
import moment from "moment";

export default defineComponent({
  name: "LandingLayout",

  components: { MobileMenu, SupportModal },

  data() {
    return {
      show_support_modal: false,
      show_mobile_menu: false,

      scrolled: false,
      lastPosition: 0,
    };
  },

  computed: {
    ...mapState({
      windowWidth: (state) => state.system.window_width,
    }),

    copyright() {
      return `Copyright © ${moment().format("YYYY")} ${window.location.host}`;
    },

    studentsLink() {
      if (process.env.NODE_ENV === "development") {
        return process.env.VUE_APP_STUDENT_RU_URL;
      }

      if (window.location.host === "partner.coorser.com") {
        return process.env.VUE_APP_STUDENT_COM_URL;
      }

      return process.env.VUE_APP_STUDENT_RU_URL;
    },
  },

  methods: {
    scrollHandler() {
      if (this.lastPosition < window.scrollY && 205 < window.scrollY) {
        this.scrolled = true;
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
      }

      this.lastPosition = window.scrollY;
    },
  },

  created() {
    window.addEventListener("scroll", this.scrollHandler);
  },

  mounted() {
    document.getElementById("app").classList.add("landing-layout");
  },

  unmounted() {
    window.removeEventListener("scroll", this.scrollHandler);
    document.getElementById("app").classList.remove("landing-layout");
  },
});
</script>

<template>
  <SupportModal v-if="show_support_modal" @close="show_support_modal = false" />

  <MobileMenu v-if="show_mobile_menu" @close="show_mobile_menu = false" />

  <header
    class="header"
    :class="{
      header_unpinned: scrolled,
      header_fixed: lastPosition > 205,
      header_shadow: lastPosition > 205,
    }"
  >
    <div class="container">
      <button class="burger-menu" @click="show_mobile_menu = true">
        <i class="icon icon-burger-menu" />
      </button>

      <router-link :to="{ name: 'HomeView' }">
        <img src="../../assets/images/logo.svg" alt="logotype" />
      </router-link>

      <nav>
        <a :href="studentsLink" target="_blank" class="header-button">
          Для учеников
        </a>
      </nav>

      <router-link
        :to="{ name: 'AuthView' }"
        class="button button_secondary button_small"
      >
        Вход / Регистрация
      </router-link>
    </div>
  </header>

  <main :class="{ main_paddings: lastPosition > 205 }">
    <router-view />
  </main>

  <footer class="footer">
    <section class="container support">
      <div class="support__info">
        <h4>Обратная связь</h4>
        <p>
          Остались вопросы? Обратитесь в техническую поддержку и мы ответим вам
          в течение 15 минут
        </p>
      </div>

      <button
        class="button button_light button_large"
        @click="show_support_modal = true"
      >
        Задать вопрос
      </button>
    </section>

    <div class="container footer__wrapper">
      <section>
        <img src="../../assets/images/logo.svg" alt="logotype" />
        <p>{{ copyright }}</p>
      </section>
      <section>
        <h5>Навигация</h5>

        <nav>
          <router-link :to="{ name: 'HomeView' }">Главная</router-link>
          <a href="">Для учеников</a>
        </nav>
      </section>
      <section>
        <h5>Документы</h5>

        <nav>
          <router-link :to="{ name: 'LicenseAgreementView' }">
            Лицензионное соглашение
          </router-link>
          <router-link :to="{ name: 'PrivacyPolicyView' }">
            Политика конфиденциальности
          </router-link>
          <router-link :to="{ name: 'OfferView' }">
            Оферта на заключение договора
          </router-link>
        </nav>
      </section>
      <section>
        <h5>Контакты</h5>

        <nav>
          <a href="mailto:help@coorser.com">
            <i class="icon icon-mail" />help@coorser.com
          </a>
        </nav>
      </section>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.header {
  padding: ($base-space * 8) 0;
  background: $background-light;

  &_shadow {
    border-bottom: 1px solid $stroke-third;
    @include main-shadow();
  }

  &_unpinned {
    opacity: 0;
    display: none;
  }

  &_fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
  }

  @media (max-width: 1023px) {
    padding: ($base-space * 4) 0;
  }

  > .container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-gap: $base-space * 12;

    @media (max-width: 1023px) {
      grid-gap: $base-space * 2;
      grid-template-columns: 28px auto 1fr;
      justify-items: end;
    }

    .burger-menu {
      width: 28px;
      height: 28px;
      display: none;
      align-items: center;
      justify-content: center;

      @media (max-width: 1023px) {
        display: flex;
      }

      > i {
        width: 28px;
        height: 28px;
        background: $text-dark-third;
      }
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1023px) {
        > img {
          width: 97px;
        }
      }
    }

    > nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space * 8;

      @media (max-width: 1023px) {
        display: none;
      }
    }
  }
}

.main_paddings {
  padding: ($base-space * 26) 0 0;

  @media (max-width: 1023px) {
    padding: ($base-space * 16) 0 0;
  }
}

.footer {
  padding: ($base-space * 30) 0 ($base-space * 24);
  background: $background-light-gray;

  @media (max-width: 1439px) {
    padding: ($base-space * 25) 0 ($base-space * 18);
  }

  @media (max-width: 1239px) {
    padding: ($base-space * 20) 0 ($base-space * 15);
  }

  @media (max-width: 1023px) {
    padding: ($base-space * 18) 0 ($base-space * 12);
  }

  @media (max-width: 767px) {
    padding: ($base-space * 15) 0 ($base-space * 10);
  }

  > .support {
    padding: ($base-space * 10);
    background: $background-main-accent;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: ($base-space * 6);
    align-items: center;

    @media (max-width: 1239px) {
      padding: ($base-space * 8);
    }

    @media (max-width: 1023px) {
      grid-template-columns: 1fr;
      justify-items: start;
    }

    @media (max-width: 767px) {
      justify-items: stretch;
    }

    h4 {
      @include h4();
      color: $text-light-primary;
    }

    p {
      margin: ($base-space * 2) 0 0;
      @include m-text-regular();
      color: $text-light-second;
    }
  }

  &__wrapper {
    margin: ($base-space * 15) auto 0;
    padding: ($base-space * 15) 0 0;
    border-top: 1px solid $stroke-second;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "logotype navigation documents contacts";
    grid-gap: $base-space * 8;

    @media (max-width: 1239px) {
      margin: ($base-space * 12) auto 0;
      padding: ($base-space * 12) 0 0;
    }

    @media (max-width: 1023px) {
      grid-template-areas: "navigation" "documents" "contacts" "logotype";
      margin: ($base-space * 8) auto 0;
      padding: ($base-space * 8) 0 0;
    }

    > section {
      &:nth-child(1) {
        grid-area: logotype;
      }

      &:nth-child(2) {
        grid-area: navigation;
      }

      &:nth-child(3) {
        grid-area: documents;
      }

      &:nth-child(4) {
        grid-area: contacts;
      }

      > img {
        width: 122px;
      }

      > p {
        color: $text-dark-third;
        margin: ($base-space * 2) 0 0;
        @include caption-regular();
      }

      > h5 {
        @include h6();
        color: $text-dark-primary;
      }

      > nav {
        margin: ($base-space * 4) 0 0;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: $base-space * 4;

        > a {
          color: $text-dark-third;
          @include m-text-regular();
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: $base-space * 2;

          > i {
            width: 20px;
            height: 20px;
            background: $text-dark-third;
          }
        }
      }
    }
  }
}
</style>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppModal",

  props: {
    size: {
      type: String,
    },

    fixed: {
      type: Boolean,
    },
  },

  emits: ["close"],
});
</script>

<template>
  <div class="modal">
    <div
      class="modal__wrapper"
      :class="{
        modal__wrapper_medium: size === 'medium',
        modal__wrapper_fixed: fixed,
      }"
    >
      <button type="button" @click="$emit('close')">
        <i class="icon icon-close" />
      </button>

      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background: $background-main-overlay;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrapper {
    background: $background-light;
    padding: ($base-space * 15);
    border-radius: 24px;
    position: relative;
    max-height: 92vh;
    overflow-y: auto;
    overscroll-behavior: contain;

    @media (max-width: 1023px) {
      padding: ($base-space * 12);
      width: calc(100vw - 64px);
      border-radius: 16px;
    }

    @media (max-width: 767px) {
      padding: ($base-space * 6);
      width: calc(100vw - 40px);
    }

    @media (max-width: 374px) {
      width: calc(100vw - 32px);
    }

    &_medium {
      padding: ($base-space * 12);

      @media (max-width: 1023px) {
        padding: ($base-space * 8);
      }

      @media (max-width: 767px) {
        padding: ($base-space * 6);
      }

      @media (max-width: 424px) {
        padding: ($base-space * 5);
      }

      @media (max-width: 374px) {
        padding: ($base-space * 4);
      }
    }

    &_fixed {
      overflow: visible;
    }

    > button {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 16px;
      right: 16px;

      > i {
        width: 32px;
        height: 32px;
        background: $text-dark-third;
      }
    }
  }
}
</style>

import API from "@/api";

const state = {
  course: "",
  modules: "",
  labels: [],
};

const getters = {
  courseChangesAvailable: (store) =>
    store.course.status !== "in_moderation" && store.course.status !== "banned",

  getLessonNumber: (state) => (id) => {
    return state.labels.find((lesson) => lesson.unit_id === id);
  },
};

const mutations = {
  SET_COURSE_INFORMATION(store, payload) {
    store.course = payload;
  },

  SET_COURSE_MODULES(store, payload) {
    store.modules = payload;
  },

  SET_UNITS_LABELS(store, payload) {
    store.labels = payload;
  },

  ADD_COURSE_MODULE(store, payload) {
    let data = payload;
    data.children = [];

    if (payload.parent_id) {
      store.modules
        .find((module) => module.id === payload.parent_id)
        .children.push(data);
      return;
    }

    store.modules.push(data);
  },

  UPDATE_COURSE_UNIT_INFO(store, payload) {
    if (payload.parent_id) {
      let parent_module =
        store.modules[
          store.modules.findIndex((module) => module.id === payload.parent_id)
        ].children;

      parent_module[
        parent_module.findIndex((module) => module.id === payload.id)
      ] = payload;
      return;
    }

    store.modules[
      store.modules.findIndex((module) => module.id === payload.id)
    ] = payload;
  },

  DELETE_COURSE_UNIT(store, payload) {
    if (payload.parent_id) {
      store.modules[
        store.modules.findIndex((module) => module.id === payload.parent_id)
      ].children = store.modules[
        store.modules.findIndex((module) => module.id === payload.parent_id)
      ].children.filter((module) => module.id !== payload.id);
      return;
    }

    store.modules = store.modules.filter((module) => module.id !== payload.id);
  },

  UPDATE_COURSE_MODULES_LIST(store, payload) {
    store.modules = payload;
  },

  UPDATE_COURSE_COMPONENTS_LIST(store, payload) {
    store.modules.find((module) => module.id === payload.parent_id).children =
      payload.data;
  },
};

const actions = {
  createNewCourse(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/courses`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCourseInformationById({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data}`)
        .then((response) => {
          commit("SET_COURSE_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateCourseById({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.put(`api/partners/courses/${data.id}`, data.data)
        .then((response) => {
          commit("SET_COURSE_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateCourseStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/courses/${data.id}/status`, {
        status: data.status,
      })
        .then((response) => {
          commit("SET_COURSE_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadCourseCoverImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/courses/${data.id}/media/cover`, data.data)
        .then((response) => {
          commit("SET_COURSE_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteCourseCoverImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.delete(`api/partners/courses/${data}/media/cover`)
        .then((response) => {
          commit("SET_COURSE_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadCourseCardImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/courses/${data.id}/media/card`, data.data)
        .then((response) => {
          commit("SET_COURSE_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteCourseCardImage({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.delete(`api/partners/courses/${data}/media/card`)
        .then((response) => {
          commit("SET_COURSE_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteCourseById(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`api/partners/courses/${data}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  cloneCourseById(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/courses/${data}/clone`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCourseUnits({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data}/units`)
        .then((response) => {
          commit("SET_COURSE_MODULES", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCourseUnitsLabels({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data}/units/labels`)
        .then((response) => {
          commit("SET_UNITS_LABELS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addCourseUnit({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/courses/${data.course_id}/units`, data.data)
        .then((response) => {
          commit("ADD_COURSE_MODULE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editCourseUnit({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.put(
        `api/partners/courses/${data.course_id}/units/${data.id}`,
        data.data
      )
        .then((response) => {
          commit("UPDATE_COURSE_UNIT_INFO", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  duplicateCourseUnit({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/courses/${data.course_id}/units/${data.id}/clone`)
        .then((response) => {
          commit("ADD_COURSE_MODULE", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteCourseUnit({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.delete(
        `api/partners/courses/${data.course_id}/units/${data.course.id}`
      )
        .then((response) => {
          commit("DELETE_COURSE_UNIT", data.course);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  changeCourseUnitsPosition({ commit, dispatch }, data) {
    if (data.data.find((module) => module.parent_id)) {
      data.data
        .filter((module) => module.parent_id)
        .forEach((module) => {
          dispatch("editCourseUnit", {
            id: module.id,
            course_id: data.course_id,
            data: { parent_id: null },
          });
        });
    }

    commit("UPDATE_COURSE_MODULES_LIST", data.data);
    dispatch("changeUnitsPosition", data.course_id);
  },

  changeModuleUnitsPosition({ commit, dispatch }, data) {
    if (data.data.find((module) => !module.parent_id)) {
      data.data
        .filter((module) => !module.parent_id)
        .forEach((module) => {
          dispatch("editCourseUnit", {
            id: module.id,
            course_id: data.course_id,
            data: { parent_id: data.parent_id },
          });
        });
    }

    commit("UPDATE_COURSE_COMPONENTS_LIST", {
      parent_id: data.parent_id,
      data: data.data,
    });
    dispatch("changeUnitsPosition", data.course_id);
  },

  changeUnitsPosition({ state }, data) {
    let modules = [];
    state.modules.forEach((module) => {
      modules.push(module);
      if (module.children && module.children.length) {
        modules = modules.concat(module.children);
      }
    });

    return new Promise((resolve, reject) => {
      API.post(
        `api/partners/courses/${data}/units/positions`,
        modules.map((item, index) => {
          return { course_unit_id: item.id, unit_position: index };
        })
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCourseStudents(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data.id}/users`, { params: data.params })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadAttachedStudents(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data.id}/users/unregistered`, {
        params: data.params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadBannedStudents(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data.id}/users/banned`, {
        params: data.params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  inviteStudentToCourse(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/courses/${data.id}/users`, data.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  cancelInviteToCourse(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(
        `api/partners/courses/${data.id}/users/unregistered/${data.user}`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  removeStudentFromCourse(_, data) {
    return new Promise((resolve, reject) => {
      API.delete(`api/partners/courses/${data.id}/users/${data.user}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  unBanStudentFromCourse(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/courses/${data.id}/users/${data.user}/unban`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { createRouter, createWebHistory } from "vue-router";
import LandingLayout from "@/components/layouts/LandingLayout.vue";
import ClearLayout from "@/components/layouts/ClearLayout.vue";
import AuthLayout from "@/components/layouts/AuthLayout.vue";
import PanelLayout from "@/components/layouts/PanelLayout.vue";
import CourseLayout from "@/components/layouts/CourseLayout.vue";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    component: ClearLayout,
    beforeEnter: (to, from, next) => {
      next({ name: "HomeView" });
    },
  },

  {
    path: "/",
    component: LandingLayout,
    children: [
      {
        path: "",
        name: "HomeView",
        component: () =>
          import(/* webpackChunkName: "Home" */ "../views/HomeView.vue"),
      },
    ],
  },

  {
    path: "/documents",
    component: LandingLayout,
    children: [
      {
        path: "",
        component: ClearLayout,
        beforeEnter: (to, from, next) => {
          next({ name: "HomeView" });
        },
      },
      {
        path: "license-agreement",
        name: "LicenseAgreementView",
        component: () =>
          import(
            /* webpackChunkName: "LicenseAgreement" */ "../views/Documents/LicenseAgreementView.vue"
          ),
      },
      {
        path: "privacy-policy",
        name: "PrivacyPolicyView",
        component: () =>
          import(
            /* webpackChunkName: "PrivacyPolicyView" */ "../views/Documents/PrivacyPolicyView.vue"
          ),
      },
      {
        path: "offer",
        name: "OfferView",
        component: () =>
          import(
            /* webpackChunkName: "OfferView" */ "../views/Documents/OfferView.vue"
          ),
      },
    ],
  },

  {
    path: "/token/login",
    component: () =>
      import(
        /* webpackChunkName: "TokenAuthView" */ "../views/TokenAuthView.vue"
      ),
  },

  {
    path: "/oauth/login",
    name: "OAuthLogin",
    component: () =>
      import(
        /* webpackChunkName: "TokenAuthView" */ "../views/TokenAuthView.vue"
      ),
  },

  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        path: "",
        name: "AuthView",
        component: () =>
          import(/* webpackChunkName: "AuthView" */ "../views/AuthView.vue"),
      },
    ],
  },

  {
    path: "/panel",
    beforeEnter: (to, from, next) => {
      if (!localStorage.token) {
        next({ name: "AuthView" });
        return;
      }

      next();
    },
    children: [
      {
        path: "",
        name: "PanelView",
        component: ClearLayout,
        beforeEnter: (to, from, next) => {
          next({ name: "CoursesView" });
        },
      },
      {
        path: "courses",
        component: PanelLayout,
        children: [
          {
            path: "",
            name: "CoursesView",
            component: () =>
              import(
                /* webpackChunkName: "CoursesView" */ "../views/Panel/Courses/CoursesView.vue"
              ),
          },
          {
            path: "available",
            name: "AvailableCoursesView",
            component: () =>
              import(
                /* webpackChunkName: "AvailableCoursesView" */ "../views/Panel/Courses/AvailableCoursesView.vue"
              ),
          },
        ],
      },

      {
        path: "course",
        component: CourseLayout,
        children: [
          {
            path: "",
            component: ClearLayout,
            beforeEnter: (to, from, next) => {
              next({ name: "CoursesView" });
            },
          },
          {
            path: ":id",
            children: [
              {
                path: "",
                name: "CourseView",
                component: () =>
                  import(
                    /* webpackChunkName: "ProfileEdit" */ "../views/Panel/Course/CourseView.vue"
                  ),
              },
              {
                path: "tasks",
                component: ClearLayout,
                children: [
                  {
                    path: "",
                    name: "CourseTasks",
                    component: () =>
                      import(
                        /* webpackChunkName: "CourseTasks" */ "../views/Panel/Course/CourseTasks.vue"
                      ),
                  },
                  {
                    path: ":unitId",
                    name: "CourseTask",
                    component: () =>
                      import(
                        /* webpackChunkName: "CourseTask" */ "../views/Panel/Course/CourseTask.vue"
                      ),
                  },
                ],
              },
              {
                path: "analytics",
                name: "CourseAnalytics",
                component: () =>
                  import(
                    /* webpackChunkName: "CourseAnalytics" */ "../views/Panel/Course/CourseAnalytics.vue"
                  ),
              },
              {
                path: "students",
                name: "CourseStudents",
                component: () =>
                  import(
                    /* webpackChunkName: "CourseStudents" */ "../views/Panel/Course/CourseStudents.vue"
                  ),
              },
              {
                path: "teachers",
                name: "CourseTeachers",
                component: () =>
                  import(
                    /* webpackChunkName: "CourseTeachers" */ "../views/Panel/Course/CourseTeachers.vue"
                  ),
              },
              {
                path: "reviews",
                name: "CourseReviews",
                component: () =>
                  import(
                    /* webpackChunkName: "CourseReviews" */ "../views/Panel/Course/CourseReviews.vue"
                  ),
              },
              {
                path: "question-bank",

                children: [
                  {
                    path: "",
                    name: "CourseQuestionsBank",
                    component: () =>
                      import(
                        /* webpackChunkName: "CourseQuestionsBank" */ "../views/Panel/Course/CourseQuestionsBank.vue"
                      ),
                  },
                  {
                    path: "create",
                    name: "CourseCreateQuestion",
                    component: () =>
                      import(
                        /* webpackChunkName: "CourseQuestionEdit" */ "../views/Panel/Course/CourseQuestionEdit.vue"
                      ),
                  },
                  {
                    path: "edit",
                    children: [
                      {
                        path: "",
                        component: () =>
                          import(
                            /* webpackChunkName: "CourseQuestionsBank" */ "../views/Panel/Course/CourseQuestionsBank.vue"
                          ),
                        beforeEnter: (to, from, next) => {
                          next({
                            name: "CourseQuestionsBank",
                            params: { id: to.params.id },
                          });
                        },
                      },
                      {
                        path: ":questionId",
                        name: "CourseEditQuestion",
                        component: () =>
                          import(
                            /* webpackChunkName: "CourseQuestionEdit" */ "../views/Panel/Course/CourseQuestionEdit.vue"
                          ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "settings",
                name: "CourseSettings",
                component: () =>
                  import(
                    /* webpackChunkName: "CourseSettings" */ "../views/Panel/Course/CourseSettings.vue"
                  ),
              },
              {
                path: "lesson",
                component: ClearLayout,
                children: [
                  {
                    path: "",
                    component: ClearLayout,
                    beforeEnter: (to, from, next) => {
                      next({
                        name: "CourseView",
                        params: { id: to.params.id },
                      });
                    },
                  },
                  {
                    path: ":lessonId",
                    name: "LessonView",
                    component: () =>
                      import(
                        /* webpackChunkName: "CourseSettings" */ "../views/Panel/Course/LessonView.vue"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: "tasks",
        component: PanelLayout,
        children: [
          {
            path: "",
            name: "TasksView",
            component: () =>
              import(
                /* webpackChunkName: "TasksView" */ "../views/Panel/Tasks/TasksView.vue"
              ),
          },
          {
            path: ":id/lesson/:unitId",
            name: "TaskView",
            component: () =>
              import(
                /* webpackChunkName: "TaskView" */ "../views/Panel/Tasks/TaskView.vue"
              ),
          },
        ],
      },

      {
        path: "finance",
        component: PanelLayout,
        children: [
          {
            path: "",
            name: "FinanceView",
            component: () =>
              import(
                /* webpackChunkName: "FinanceView" */ "../views/Panel/Finance/FinanceView.vue"
              ),
          },
        ],
      },

      {
        path: "profile",
        component: PanelLayout,
        children: [
          {
            path: "",
            name: "ProfileView",
            component: () =>
              import(
                /* webpackChunkName: "ProfileVie" */ "../views/Panel/Profile/ProfileView.vue"
              ),
          },
          {
            path: "edit",
            name: "ProfileEdit",
            component: () =>
              import(
                /* webpackChunkName: "ProfileEdit" */ "../views/Panel/Profile/ProfileEdit.vue"
              ),
          },
          {
            path: "achievements",
            name: "AchievementsView",
            component: () =>
              import(
                /* webpackChunkName: "AchievementsView" */ "../views/Panel/Profile/ProfileView.vue"
              ),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  scrollBehavior() {
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

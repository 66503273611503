<script>
export default {
  name: "LessonQuestionTemplate",

  props: {
    question: {
      type: Object,
    },

    index: {
      type: Number,
    },
  },

  data() {
    return {
      answer: "",
      answer_success: null,
      show_error: false,
    };
  },

  watch: {
    answer() {
      this.answer_success = null;
      this.show_error = false;
    },
  },

  methods: {
    checkAnswer() {
      if (!this.answer.trim().length) {
        this.show_error = true;
        return;
      }

      for (let i in this.question.answers) {
        if (
          this.question.answers[i].toLowerCase() ===
          this.answer.toLowerCase().trim()
        ) {
          this.answer_success = true;
          return;
        }
      }

      this.answer_success = false;
    },
  },
};
</script>

<template>
  <section class="template-question">
    <h5>{{ question.name }}</h5>

    <p v-if="question.description">{{ question.description }}</p>

    <form @submit.prevent="checkAnswer">
      <label
        :for="`answer-${index}`"
        class="answer"
        :class="{
          answer_error: answer_success === false,
          answer_success: answer_success === true,
        }"
      >
        <input
          type="text"
          placeholder="Введите ответ"
          v-model="answer"
          :id="`answer-${index}`"
        />

        <i
          class="icon"
          :class="{
            'icon-close-circle': answer_success === false,
            'icon-done': answer_success === true,
          }"
        />
      </label>

      <span v-if="show_error" class="error">Заполните поле ответа</span>

      <button
        class="button button_secondary button_medium"
        type="submit"
        v-if="answer_success === null"
      >
        Проверить
      </button>

      <button
        type="button"
        class="text-button text-button_medium"
        @click="answer_success = null"
        v-if="answer_success === false"
      >
        Попробовать снова <i class="icon icon-retry" />
      </button>
    </form>
  </section>
</template>

<style scoped lang="scss">
.template-question {
  margin: ($base-space * 2) (-$base-space * 10);
  padding: ($base-space * 10);
  border-radius: 16px;
  background-color: $background-ultralight-gray;

  @media (max-width: 1023px) {
    margin: ($base-space * 2) 0;
    padding: ($base-space * 4);
  }

  &:first-child {
    margin: 0 (-$base-space * 10) ($base-space * 2);

    @media (max-width: 1023px) {
      margin: 0 0 ($base-space * 2);
    }
  }

  > h5 {
    @include l-text-regular();
    color: $text-dark-primary;
  }

  > p {
    margin: ($base-space) 0 0;
    color: $text-dark-second;
    @include m-text-regular();
  }

  form {
    display: block;

    > span {
      display: block;
      padding: 0;
      margin: ($base-space * 2) 0 0;
    }

    > button {
      margin: ($base-space * 6) 0 0;

      @media (max-width: 1023px) {
        margin: ($base-space * 4) 0 0;
      }

      &.button {
        @media (max-width: 1023px) {
          width: 100%;
        }
      }
    }
  }
}

.answer {
  margin: ($base-space * 6) 0 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: $base-space * 2;
  border-radius: 16px;
  background-color: $stroke-light;
  border: 1px solid $stroke-light;
  height: 64px;
  padding: 0 ($base-space * 5);
  transition: ease-out 0.05s;

  @media (max-width: 1023px) {
    margin: ($base-space * 4) 0 0;
  }

  &:hover {
    border-color: $stroke-fourth;
  }

  &:focus-within {
    border-color: $stroke-accent;
  }

  > i {
    width: 32px;
    height: 32px;
  }

  &_error {
    border-color: $text-error;

    &:hover {
      border-color: $text-error;
    }

    &:focus-within {
      border-color: $text-error;
    }

    > i {
      background-color: $text-error;
    }
  }

  &_success {
    border-color: $text-success;

    &:hover {
      border-color: $text-success;
    }

    &:focus-within {
      border-color: $text-success;
    }

    > i {
      background-color: $text-success;
    }
  }

  > input {
    width: 100%;
    @include m-text-regular();
    color: $text-dark-primary;
    border: none;
    outline: none;

    &::placeholder {
      color: $text-dark-placeholder;
    }
  }
}
</style>

import API from "@/api";
import moment from "moment";
import router from "@/router";

const state = {
  user: "",
};

const mutations = {
  SET_USER_INFORMATION(store, payload) {
    store.user = payload;

    if (store.user.birth_date) {
      store.user.birth_date = moment(payload.birth_date, "YYYY-MM-DD").format(
        "DD.MM.YYYY"
      );
    }

    if (!store.user.partner) {
      store.user.partner = { additional_data: "" };
    }

    if (!store.user?.partner.additional_data) {
      store.user.partner.additional_data = { about: "" };
    }

    if (
      store.user?.partner.additional_data &&
      !store.user?.partner.additional_data?.about
    ) {
      store.user.partner.additional_data = {
        ...store.user.partner.additional_data,
        ...{ about: "" },
      };
    }
  },
};

const actions = {
  getAuthMethods(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/methods`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendOtpRequest(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/otp`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyUserPhone(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/otp/verify`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loginUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/partner/login`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          localStorage.setItem("token", response.data.token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  registerUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/partner/register`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          localStorage.setItem("token", response.data.token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadProfileInformation({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/me/info`)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status === 406) {
            router.push({
              name: "AuthView",
              query: { status: "banned" },
            });
          }
          reject(error);
        });
    });
  },

  updateProfileInformation({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/me/update`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadUserAvatar({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/me/media`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteUserAvatar({ commit }) {
    return new Promise((resolve, reject) => {
      API.delete(`api/partners/me/media`)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkUserInn(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/auth/suggestion`, { inn: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadUserDocument({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/partners/me/documents`, data)
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteUserDocument({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.delete(`api/partners/me/documents`, { data: data })
        .then((response) => {
          commit("SET_USER_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUserCourses(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/partners/${data.teacher_id}/courses`, {
        params: data.params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadUserTransactionsHistory(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/payments/operations`, {
        params: data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

<script>
import { useVuelidate } from "@vuelidate/core";
import AppTextarea from "@/components/Ui/AppTextarea.vue";
import AppInput from "@/components/Ui/AppInput.vue";
import { email, helpers, required } from "@vuelidate/validators";
import AppModal from "@/components/Ui/AppModal.vue";
import { mapActions } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SupportModal",

  emits: ["close"],

  components: { AppModal, AppInput, AppTextarea },

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      email: "",
      message: "",

      button_loader: false,
      show_success_modal: false,
    };
  },

  validations() {
    return {
      email: {
        required: helpers.withMessage("Заполните поле", required),
        email: helpers.withMessage(
          "Некорректный адрес электронной почты!",
          email
        ),
      },
      message: {
        required: helpers.withMessage("Заполните поле", required),
      },
    };
  },

  methods: {
    ...mapActions({
      sendSupportRequest: "system/sendSupportRequest",
    }),

    sendSupportMessage() {
      this.v$.$validate();

      if (!this.button_loader && !this.v$.$error) {
        this.button_loader = true;

        this.sendSupportRequest({
          text: this.message,
          email: this.email,
          type: "partner",
        })
          .then(() => {
            this.show_success_modal = true;
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },
  },
});
</script>

<template>
  <AppModal @close="$emit('close')">
    <div class="support-modal" v-if="!show_success_modal">
      <h4>Обратная связь</h4>
      <p>
        Остались вопросы? Обратитесь в техническую поддержку и мы ответим вам в
        течение 15 минут
      </p>

      <form @submit.prevent="sendSupportMessage">
        <AppInput
          label="Электронная почта"
          id="email"
          v-model="email"
          :error="v$.email?.$errors[0]?.$message"
        />

        <AppTextarea
          label="Ваш вопрос"
          id="message"
          v-model="message"
          :error="v$.message?.$errors[0]?.$message"
        />

        <fieldset>
          <button class="button button_large" :disabled="button_loader">
            Отправить
          </button>
          <p>
            Нажимая «Отправить», вы соглашаетесь с
            <router-link :to="{ name: 'PrivacyPolicyView' }">
              порядком обработки и защиты пресональных данных
            </router-link>
          </p>
        </fieldset>
      </form>
    </div>

    <div class="info-modal" v-else>
      <img src="../assets/images/success.svg" alt="" />

      <h3>Сообщение отправлено</h3>

      <p>Спасибо за ваш вопрос! Мы свяжемся с вами в ближайшее время.</p>

      <div class="info-modal__actions">
        <button class="button button_large" @click="$emit('close')">
          Закрыть
        </button>
      </div>
    </div>
  </AppModal>
</template>

<style scoped lang="scss">
.support-modal {
  max-width: 506px;

  @media (max-width: 1023px) {
    max-width: none;
  }

  > h4 {
    @include h2();
    color: $text-dark-primary;
    width: calc(100% - 32px);
  }

  > p {
    margin: ($base-space * 4) 0 0;
    color: $text-dark-second;
    @include m-text-regular();

    @media (max-width: 1023px) {
      margin: ($base-space * 3) 0 0;
    }
  }

  > form {
    margin: ($base-space * 8) 0 0;

    @media (max-width: 1023px) {
      margin: ($base-space * 6) 0 0;
    }

    > .app-textarea {
      margin: ($base-space * 4) 0 0;

      @media (max-width: 1023px) {
        margin: ($base-space * 3) 0 0;
      }
    }

    > fieldset {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ($base-space * 4) ($base-space * 6);
      margin: ($base-space * 8) 0 0;

      @media (max-width: 1023px) {
        margin: ($base-space * 6) 0 0;
      }

      @media (max-width: 767px) {
        flex-flow: column;

        > button {
          width: 100%;
        }
      }

      > p {
        @include caption-regular();
        color: $text-dark-third;

        @media (max-width: 767px) {
          text-align: center;
        }

        > a {
          color: $text-accent;
        }
      }
    }
  }
}
</style>

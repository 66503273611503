import API from "@/api";

const state = { categories: "" };

const mutations = {
  SET_COURSES_CATEGORIES(store, payload) {
    store.categories = payload;
  },
};

const actions = {
  loadCoursesList(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses`, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCoursesCategories({ commit }) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/categories`)
        .then((response) => {
          commit("SET_COURSES_CATEGORIES", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCoursesWithTasks(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/withTasks`, { params: data })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

<script>
import { defineComponent } from "vue";
import AppModal from "@/components/Ui/AppModal.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ComponentRenameModal from "@/components/Course/ComponentRenameModal.vue";
import AppDrawer from "@/components/Ui/AppDrawer.vue";

export default defineComponent({
  name: "CourseComponent",
  components: { AppDrawer, ComponentRenameModal, AppModal },

  props: {
    component: {
      type: Object,
      require: true,
    },

    index: {
      type: [Number, String],
      require: true,
    },

    size: {
      type: [String],
      default: "big",
    },

    type: {
      type: String,
      default: "edit",
    },
  },

  data() {
    return {
      course_options: [
        { id: "rename", name: "Переименовать", icon: "pencil" },
        { id: "duplicate", name: "Дублировать", icon: "copy" },
        { id: "delete", name: "Удалить", icon: "trash" },
      ],

      show_options: false,
      button_loader: false,
      show_rename_modal: false,
      show_delete_modal: false,
      show_component_actions: false,
    };
  },

  computed: {
    ...mapState({
      windowWidth: (state) => state.system.window_width,
      course: (state) => state.course.course,
    }),

    ...mapGetters({
      courseChangesAvailable: "course/courseChangesAvailable",
    }),
  },

  watch: {
    windowWidth(newValue) {
      if (newValue > 1023) {
        this.show_component_actions = false;
      }
    },
  },

  methods: {
    ...mapActions({
      deleteCourseUnit: "course/deleteCourseUnit",
      duplicateCourseUnit: "course/duplicateCourseUnit",
    }),

    ...mapMutations({
      setNotification: "system/SET_NOTIFICATION",
    }),

    hideComponentOptions() {
      this.show_options = false;
    },

    componentAction(action) {
      this.show_component_actions = false;
      switch (action) {
        case "rename":
          this.show_rename_modal = true;
          break;
        case "duplicate":
          this.duplicateCourseUnit({
            course_id: this.$route.params.id,
            id: this.component.id,
          })
            .then(() => {
              this.setNotification({
                type: "success",
                name: "Компонент продублирован",
              });
            })
            .catch((error) => {
              this.setNotification({
                type: "error",
                name: "Что-то пошло не так",
                description: error.response.data.message,
              });
            });
          break;
        case "delete":
          this.show_delete_modal = true;
          break;
      }

      this.show_options = false;
    },

    deleteComponent() {
      if (!this.button_loader) {
        this.button_loader = true;

        this.deleteCourseUnit({
          course_id: this.$route.params.id,
          course: this.component,
        })
          .then(() => {
            this.setNotification({
              type: "success",
              name: "Компонент удален",
            });
            this.show_delete_modal = false;
          })
          .catch((error) => {
            this.setNotification({
              type: "error",
              name: "Что-то пошло не так",
              description: error.response.data.message,
            });
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },
  },
});
</script>

<template>
  <div
    class="course-lesson"
    :class="{
      'course-lesson_small': size === 'small',
      'course-lesson_active': show_options,
    }"
  >
    <AppModal
      size="medium"
      v-if="show_delete_modal && courseChangesAvailable"
      @close="show_delete_modal = false"
    >
      <div class="default-modal">
        <h3>Удалить компонент</h3>

        <p>Вы действительно хотите удалить компонент «{{ component.name }}»?</p>

        <div class="default-modal__actions">
          <button
            class="button button_large"
            :disabled="button_loader"
            @click="deleteComponent"
          >
            Удалить
          </button>

          <button
            class="button button_large button_secondary"
            @click="show_delete_modal = false"
          >
            Отмена
          </button>
        </div>
      </div>
    </AppModal>

    <ComponentRenameModal
      @close="show_rename_modal = false"
      v-if="show_rename_modal && courseChangesAvailable"
      :component="component"
    />

    <AppDrawer
      @close="show_component_actions = false"
      v-if="show_component_actions && courseChangesAvailable"
    >
      <div class="drawer-component-header">
        <img
          :src="
            require(`../../assets/images/course-types/${component.type}.svg`)
          "
          :alt="component.name"
        />

        <h3>{{ component.name }}</h3>
      </div>

      <div class="drawer__content">
        <router-link
          :to="{ name: 'LessonView', params: { lessonId: component.id } }"
        >
          <i class="icon icon-edit" /> Редактировать
        </router-link>

        <button
          v-for="(option, index) in course_options"
          :key="option.id"
          @click="componentAction(option.id)"
          :class="{ 'delete-button': index === course_options.length - 1 }"
        >
          <i class="icon" :class="`icon-${option.icon}`" /> {{ option.name }}
        </button>
      </div>

      <div class="drawer__actions">
        <button
          class="button button_secondary button_large"
          @click="show_component_actions = false"
        >
          Отмена
        </button>
      </div>
    </AppDrawer>

    <span class="course-lesson__index">
      {{ index }}
    </span>

    <div class="course-lesson__info">
      <h5>{{ component.name }}</h5>
    </div>

    <div class="course-lesson__actions" v-if="courseChangesAvailable">
      <Popper
        content="Перетащить"
        v-if="type !== 'preview'"
        hover
        arrow
        offset-distance="8"
      >
        <button
          class="button-icon button-icon_grey button-icon_small action-button handle"
        >
          <i class="icon icon-burger-menu" />
        </button>
      </Popper>

      <Popper
        content="Редактировать"
        v-if="type !== 'preview'"
        hover
        arrow
        offset-distance="8"
      >
        <router-link
          :to="{ name: 'LessonView', params: { lessonId: component.id } }"
          class="button-icon button-icon_grey button-icon_small action-button"
        >
          <i class="icon icon-edit" />
        </router-link>
      </Popper>

      <button
        class="button-icon button-icon_grey button-icon_small action-button"
        v-if="false"
      >
        <i class="icon icon-publish" />
      </button>

      <div
        class="course-lesson__more"
        v-click-outside="hideComponentOptions"
        v-if="type !== 'preview'"
      >
        <button
          @click="show_options = !show_options"
          class="button-icon button-icon_grey button-icon_small action-button"
        >
          <i class="icon icon-more" />
        </button>

        <div class="course-lesson__options" v-if="show_options">
          <button
            v-for="option in course_options"
            :key="option.id"
            @click="componentAction(option.id)"
          >
            <i class="icon" :class="`icon-${option.icon}`" /> {{ option.name }}
          </button>
        </div>
      </div>

      <img
        :src="require(`../../assets/images/course-types/${component.type}.svg`)"
        :alt="component.name"
      />
    </div>

    <button
      class="button-icon button-icon_medium"
      @click="show_component_actions = true"
      v-if="courseChangesAvailable && type !== 'preview'"
    >
      <i class="icon icon-more" />
    </button>
  </div>
</template>

<style scoped lang="scss">
.course-lesson {
  background-color: $background-light;
  border: 1px solid $stroke-fourth;
  border-radius: 16px;
  padding: ($base-space * 8);
  display: grid;
  grid-template-columns: auto 1fr 296px;
  align-items: center;
  grid-gap: $base-space * 4;
  transition: ease-out 0.05s;

  @media (max-width: 1023px) {
    padding: ($base-space * 5) ($base-space * 6);
    grid-template-columns: auto 1fr auto;
    grid-gap: $base-space * 3;
  }

  @media (max-width: 767px) {
    padding: ($base-space * 4) ($base-space * 6);
  }

  &:hover {
    .action-button {
      opacity: 1;
    }
  }

  &_small {
    padding: $base-space * 5;
    background-color: $background-ultralight-gray;

    @media (max-width: 1023px) {
      padding: ($base-space * 3) ($base-space * 4);
    }

    > .course-lesson__index {
      width: 40px;
      height: 40px;
      @include s-text-regular();

      @media (max-width: 1023px) {
        width: 32px;
        height: 32px;
      }
    }
  }

  &_active {
    background: $background-ultralight-accent;

    .action-button {
      opacity: 1;
    }
  }

  &__index {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $stroke-accent;
    border-radius: 8px;
    color: $text-accent;
    @include m-text-regular();

    @media (max-width: 1023px) {
      width: 32px;
      height: 32px;
    }
  }

  &__info {
    > h5 {
      @include m-text-regular();
      color: $text-dark-primary;
      @include max-stroke(1);
    }

    > p {
      @include s-text-regular();
      color: $text-dark-third;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    @media (max-width: 1023px) {
      display: none;
    }

    &:deep(.popper) {
      padding: ($base-space * 3) ($base-space * 4);
      @include s-text-regular();
      background-color: $background-light;
      color: $text-dark-primary;
      border-radius: 8px;
      @include main-shadow();
    }

    :deep(.popper #arrow::before) {
      background: $background-light;
    }

    > img {
      width: 40px;
      height: 40px;
      margin: 0 0 0 ($base-space * 6);
    }
  }

  > button {
    display: none;

    @media (max-width: 1023px) {
      display: flex;
    }
  }

  &__more {
    position: relative;
  }

  &__options {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background-color: $background-light;
    padding: ($base-space * 2) 0;
    width: 240px;
    @include main-shadow();
    border-radius: 8px;
    z-index: 2;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    > button {
      width: 100%;
      padding: ($base-space * 2.5) ($base-space * 4);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space * 2;
      color: $text-dark-primary;
      @include m-text-regular();

      &:hover {
        background-color: $background-light-accent;
      }

      > i {
        width: 24px;
        height: 24px;
        background-color: $text-dark-primary;
      }
    }
  }
}

.action-button {
  width: 40px;
  height: 40px;
  opacity: 0;
  &:not(:first-of-type) {
    margin: 0 0 0 ($base-space * 2);
  }
}
</style>

<script>
import { defineComponent } from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import CourseHeader from "@/components/Course/CourseHeader.vue";
import LessonHeader from "@/components/Lesson/LessonHeader.vue";

export default defineComponent({
  name: "CourseLayout",

  components: { LessonHeader, CourseHeader },

  computed: {
    ...mapState({
      course: (state) => state.course.course,
      categories: (state) => state.courses.categories,
      user: (state) => state.user.user,
      lesson: (state) => state.lesson.lesson,
    }),
  },

  methods: {
    ...mapActions({
      loadCourseInformationById: "course/loadCourseInformationById",
      loadCoursesCategories: "courses/loadCoursesCategories",
      loadProfileInformation: "user/loadProfileInformation",
    }),

    ...mapMutations({
      setNotification: "system/SET_NOTIFICATION",
      setCourseInformation: "course/SET_COURSE_INFORMATION",
    }),
  },

  beforeMount() {
    this.loadCourseInformationById(this.$route.params.id).catch(() => {
      this.$router.push({ name: "CoursesView" });
    });
    this.loadCoursesCategories();
    this.loadProfileInformation();
  },

  unmounted() {
    this.setCourseInformation("");
  },
});
</script>

<template>
  <template v-if="course && categories && user">
    <CourseHeader v-if="$route.name !== 'LessonView'" />

    <LessonHeader v-if="lesson && $route.name === 'LessonView'" />

    <router-view />
  </template>

  <Transition>
    <div class="panel-overlay" v-if="!course || !categories">
      <img src="../../assets/images/logo.svg" alt="logotype" />
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-in;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<script>
import { defineComponent } from "vue";
import CourseComponent from "@/components/Course/CourseComponent.vue";
import CreateCourseComponentModal from "@/components/Course/CreateCourseComponentModal.vue";
import AppModal from "@/components/Ui/AppModal.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import wordDeclension from "../../package/helpers/wordDeclension";
import ComponentRenameModal from "@/components/Course/ComponentRenameModal.vue";
import AppDrawer from "@/components/Ui/AppDrawer.vue";
import draggable from "vuedraggable";

export default defineComponent({
  name: "CourseModule",
  components: {
    draggable,
    AppDrawer,
    ComponentRenameModal,
    AppModal,
    CreateCourseComponentModal,
    CourseComponent,
  },

  props: {
    module: {
      type: Object,
      require: true,
    },

    parentIndex: {
      type: [Number, String],
      require: true,
    },

    type: {
      type: String,
      default: "edit",
    },
  },

  data() {
    return {
      show_sections: true,
      show_create_component_modal: false,

      show_options: false,
      button_loader: false,
      show_rename_modal: false,
      show_delete_modal: false,
      show_module_actions: false,

      module_options: [{ id: "delete", name: "Удалить", icon: "trash" }],
    };
  },

  computed: {
    ...mapState({
      windowWidth: (state) => state.system.window_width,
      course: (state) => state.course.course,
    }),

    ...mapGetters({
      courseChangesAvailable: "course/courseChangesAvailable",
    }),

    lessonsCount() {
      return this.module.children.filter((module) => module.type === "lesson")
        .length;
    },

    draggableList: {
      get() {
        return this.module.children;
      },
      set(value) {
        this.changeModuleUnitsPosition({
          course_id: this.$route.params.id,
          parent_id: this.module.id,
          data: value,
        });
      },
    },
  },

  watch: {
    windowWidth(newValue) {
      if (newValue > 1023) {
        this.show_sections = true;
        this.show_module_actions = false;
      }
    },
  },

  methods: {
    ...mapActions({
      changeModuleUnitsPosition: "course/changeModuleUnitsPosition",
    }),

    wordDeclension,
    ...mapActions({
      deleteCourseUnit: "course/deleteCourseUnit",
    }),

    ...mapMutations({
      setNotification: "system/SET_NOTIFICATION",
    }),

    hideModuleOptions() {
      this.show_options = false;
    },

    moduleAction(action) {
      this.show_module_actions = false;

      switch (action) {
        case "rename":
          this.show_rename_modal = true;
          break;
        case "delete":
          this.show_delete_modal = true;
          break;
      }

      this.show_options = false;
    },

    deleteComponent() {
      if (!this.button_loader) {
        this.button_loader = true;

        this.deleteCourseUnit({
          course_id: this.$route.params.id,
          course: this.module,
        })
          .then(() => {
            this.setNotification({
              type: "success",
              name: "Компонент удален",
            });

            this.show_delete_modal = false;
          })
          .catch((error) => {
            this.setNotification({
              type: "error",
              name: "Что-то пошло не так",
              description: error.response.data.message,
            });
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },
  },

  beforeMount() {
    if (this.type === "preview") {
      this.show_sections = false;
    }
  },
});
</script>

<template>
  <div class="course-module">
    <CreateCourseComponentModal
      v-if="show_create_component_modal && courseChangesAvailable"
      @close="show_create_component_modal = false"
      :index="module.children.length"
      type="module"
      :parent-id="module.id"
    />

    <ComponentRenameModal
      @close="show_rename_modal = false"
      v-if="show_rename_modal && courseChangesAvailable"
      :component="module"
      type="module"
    />

    <AppModal
      size="medium"
      v-if="show_delete_modal && course.status !== 'in_moderation'"
      @close="show_delete_modal = false"
    >
      <div class="default-modal">
        <h3>Удалить модуль</h3>

        <p>Вы действительно хотите удалить модуль «{{ module.name }}»?</p>

        <div class="default-modal__actions">
          <button
            class="button button_large"
            :disabled="button_loader"
            @click="deleteComponent"
          >
            Удалить
          </button>

          <button
            class="button button_large button_secondary"
            @click="show_delete_modal = false"
          >
            Отмена
          </button>
        </div>
      </div>
    </AppModal>

    <AppDrawer
      @close="show_module_actions = false"
      v-if="show_module_actions && courseChangesAvailable"
    >
      <div class="drawer-component-header">
        <img
          :src="require(`../../assets/images/course-types/${module.type}.svg`)"
          :alt="module.name"
        />

        <h3>{{ module.name }}</h3>
      </div>

      <div class="drawer__content">
        <button @click="moduleAction('rename')">
          <i class="icon icon-pencil" /> Переименовать
        </button>
        <button
          v-for="(option, index) in module_options"
          :key="option.id"
          @click="moduleAction(option.id)"
          :class="{ 'delete-button': index === module_options.length - 1 }"
        >
          <i class="icon" :class="`icon-${option.icon}`" /> {{ option.name }}
        </button>
      </div>

      <div class="drawer__actions">
        <button
          class="button button_secondary button_large"
          @click="show_module_actions = false"
        >
          Отмена
        </button>
      </div>
    </AppDrawer>

    <div
      class="course-module__header"
      :class="{ 'course-module__header_active': show_options }"
      @click="windowWidth > 1023 ? (show_sections = !show_sections) : ''"
    >
      <span>
        {{ parentIndex }}
      </span>

      <div class="course-module__info">
        <h5>{{ module.name }}</h5>
        <p>
          <i class="icon icon-play-circle" />
          {{
            `${lessonsCount} ${wordDeclension(lessonsCount, [
              "Урок",
              "Урока",
              "Уроков",
            ])}`
          }}
        </p>
      </div>

      <div class="course-module__actions" v-if="courseChangesAvailable">
        <Popper
          content="Перетащить"
          hover
          arrow
          v-if="type !== 'preview'"
          offset-distance="8"
        >
          <button
            class="button-icon button-icon_grey button-icon_small action-button handle"
          >
            <i class="icon icon-burger-menu" />
          </button>
        </Popper>

        <Popper
          content="Переименовать"
          hover
          arrow
          v-if="type !== 'preview'"
          offset-distance="8"
        >
          <button
            @click.stop="moduleAction('rename')"
            class="button-icon button-icon_grey button-icon_small action-button action-button"
          >
            <i class="icon icon-pencil" />
          </button>
        </Popper>

        <div
          class="course-module__more"
          v-click-outside="hideModuleOptions"
          v-if="type !== 'preview'"
        >
          <button
            @click.stop="show_options = !show_options"
            class="button-icon button-icon_grey button-icon_small action-button action-button"
          >
            <i class="icon icon-more" />
          </button>

          <div class="course-module__options" v-if="show_options">
            <button
              v-for="option in module_options"
              :key="option.id"
              @click.stop="moduleAction(option.id)"
            >
              <i class="icon" :class="`icon-${option.icon}`" />
              {{ option.name }}
            </button>
          </div>
        </div>

        <i
          class="icon icon-short-arrow-down"
          :class="{ arrow_active: show_sections }"
        />
      </div>

      <button
        class="button-icon button-icon_medium"
        @click="show_module_actions = true"
        v-if="courseChangesAvailable && type !== 'preview'"
      >
        <i class="icon icon-more" />
      </button>
    </div>

    <div class="course-module__section sections" v-if="show_sections">
      <draggable
        class="sections__list"
        :class="{ sections__list_empty: !draggableList.length }"
        ghost-class="ghost"
        v-model="draggableList"
        group="modules"
        item-key="id"
        handle=".handle"
      >
        <template #item="{ element, index }">
          <CourseComponent
            :component="element"
            :index="`${parentIndex}.${index + 1}`"
            size="small"
            :type="type"
          />
        </template>
      </draggable>

      <button
        class="text-button text-button_medium"
        @click="show_create_component_modal = !show_create_component_modal"
        v-if="courseChangesAvailable && type !== 'preview'"
      >
        Создать в модуле <i class="icon icon-plus" />
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.course-module {
  background-color: $background-light;
  border: 1px solid $stroke-fourth;
  border-radius: 16px;

  &__header {
    padding: ($base-space * 8);
    display: grid;
    grid-template-columns: 48px 1fr 192px;
    align-items: center;
    grid-gap: $base-space * 4;
    transition: ease-out 0.05s;
    width: 100%;
    cursor: pointer;
    overflow: hidden;

    @media (max-width: 1023px) {
      padding: ($base-space * 5) ($base-space * 6);
      grid-template-columns: auto 1fr auto;
    }

    @media (max-width: 767px) {
      padding: ($base-space * 4) ($base-space * 6);
    }

    @media (max-width: 424px) {
      padding: ($base-space * 4) ($base-space * 5);
    }

    @media (max-width: 374px) {
      padding: ($base-space * 4) ($base-space * 4);
    }

    &:hover {
      background: $background-ultralight-accent;
      border-color: $stroke-third;

      .action-button {
        opacity: 1;
      }
    }

    > span {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $stroke-accent;
      border-radius: 8px;
      color: $text-accent;
      @include m-text-regular();

      @media (max-width: 1023px) {
        width: 32px;
        height: 32px;
      }
    }

    &_active {
      background: $background-ultralight-accent;

      .action-button {
        opacity: 1;
      }
    }

    > button {
      display: none;

      @media (max-width: 1023px) {
        display: flex;
      }
    }
  }

  &__info {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    gap: $base-space;

    > h5 {
      @include h5();
      color: $text-dark-primary;
      @include max-stroke(1);
    }

    > p {
      @include m-text-regular();
      color: $text-dark-third;
      display: flex;
      align-items: center;
      justify-items: flex-start;
      gap: $base-space;

      > i {
        width: 24px;
        height: 24px;
        background-color: $text-dark-third;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 1023px) {
      display: none;
    }

    &:deep(.popper) {
      padding: ($base-space * 3) ($base-space * 4);
      @include s-text-regular();
      background-color: $background-light;
      color: $text-dark-primary;
      border-radius: 8px;
      @include main-shadow();
    }

    :deep(.popper #arrow::before) {
      background: $background-light;
    }

    > i {
      width: 32px;
      height: 32px;
      background-color: $text-dark-third;
      margin: 0 0 0 ($base-space * 6);
      transition: ease-in 0.05s;
    }
  }

  &__more {
    position: relative;
  }

  &__options {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background-color: $background-light;
    padding: ($base-space * 2) 0;
    width: 240px;
    @include main-shadow();
    border-radius: 8px;
    z-index: 2;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    > button {
      width: 100%;
      padding: ($base-space * 2.5) ($base-space * 4);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space * 2;
      color: $text-dark-primary;
      @include m-text-regular();

      &:hover {
        background-color: $background-light-accent;
      }

      > i {
        width: 24px;
        height: 24px;
        background-color: $text-dark-primary;
      }
    }
  }
}

.sections {
  border-top: 1px solid $stroke-fourth;
  padding: ($base-space * 8);

  @media (max-width: 1023px) {
    padding: ($base-space * 5) ($base-space * 6);
  }

  @media (max-width: 767px) {
    padding: ($base-space * 4) ($base-space * 6);
  }

  @media (max-width: 424px) {
    padding: ($base-space * 4) ($base-space * 5);
  }

  @media (max-width: 374px) {
    padding: ($base-space * 4) ($base-space * 4);
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $base-space * 2;
    min-height: 60px;
  }

  > button {
    &:not(:first-child) {
      margin: ($base-space * 6) 0 0;

      @media (max-width: 767px) {
        margin: ($base-space * 4) 0 0;
      }
    }
  }
}

.arrow_active {
  transform: rotate(180deg);
}

.action-button {
  width: 40px;
  height: 40px;
  opacity: 0;
  &:not(:first-of-type) {
    margin: 0 0 0 ($base-space * 2);
  }
}
</style>

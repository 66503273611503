import API from "@/api";

const state = {
  course: "",
};

const mutations = {
  SET_COURSE_INFORMATION(store, payload) {
    store.course = payload;
  },
};

const actions = {
  loadCourseAnalyticsGraph(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data.id}/users/statistics/graph`, {
        params: data.params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loadCourseAnalyticsStatistics(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/partners/courses/${data.id}/users/statistics/total`, {
        params: data.params,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

<script>
import AppModal from "@/components/Ui/AppModal.vue";
import CourseActionsDrawer from "@/components/Course/CourseActionsDrawer.vue";
import AppInput from "@/components/Ui/AppInput.vue";
import CoursePagePreview from "@/components/Course/CoursePagePreview.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { getCourseStatusInfo } from "@/package/helpers/courseStatuses";

export default {
  name: "CourseHeader",

  components: { CoursePagePreview, AppInput, CourseActionsDrawer, AppModal },

  data() {
    return {
      show_more_actions: false,
      show_share_modal: false,
      show_hide_modal: false,
      show_delete_modal: false,
      show_legal_modal: false,
      show_moderation_modal: false,
      show_mobile_action: false,
      show_preview: false,

      button_loader: false,
    };
  },

  computed: {
    ...mapState({
      course: (state) => state.course.course,
      categories: (state) => state.courses.categories,
      user: (state) => state.user.user,
      windowWidth: (state) => state.system.window_width,
    }),

    ...mapGetters({
      courseChangesAvailable: "course/courseChangesAvailable",
    }),

    courseActions() {
      switch (this.course.status) {
        case "in_moderation":
          return [
            {
              id: "cancelModeration",
              name: "Отозвать с модерации",
              icon: "speach-cancel",
            },
            {
              id: "deleteCourse",
              name: "Удалить курс",
              icon: "trash",
              class: "delete-button",
            },
          ];
        case "planned":
          return [
            {
              id: "shareCourse",
              name: "Поделиться",
              icon: "share",
            },
            {
              id: "hideCourse",
              name: "Скрыть курс",
              icon: "hide",
            },
            {
              id: "deleteCourse",
              name: "Удалить курс",
              icon: "trash",
              class: "delete-button",
            },
          ];
        case "started":
          return [
            {
              id: "shareCourse",
              name: "Поделиться",
              icon: "share",
            },
            {
              id: "hideCourse",
              name: "Скрыть курс",
              icon: "hide",
            },
            {
              id: "deleteCourse",
              name: "Удалить курс",
              icon: "trash",
              class: "delete-button",
            },
          ];
        case "ended":
          return [
            {
              id: "shareCourse",
              name: "Поделиться",
              icon: "share",
            },
            {
              id: "hideCourse",
              name: "Скрыть курс",
              icon: "hide",
            },
            {
              id: "deleteCourse",
              name: "Удалить курс",
              icon: "trash",
              class: "delete-button",
            },
          ];
        default:
          return [
            {
              id: "deleteCourse",
              name: "Удалить курс",
              icon: "trash",
              class: "delete-button",
            },
          ];
      }
    },

    courseShareLink() {
      if (process.env.NODE_ENV === "development") {
        return `${process.env.VUE_APP_STUDENT_RU_URL}catalog/${this.course.id}`;
      }

      if (window.location.host === "coorser.com") {
        return `${process.env.VUE_APP_STUDENT_COM_URL}catalog/${this.course.id}`;
      }

      return `${process.env.VUE_APP_STUDENT_RU_URL}catalog/${this.course.id}`;
    },
  },

  watch: {
    windowWidth(newValue) {
      if (newValue > 1023) {
        this.show_mobile_action = false;
      }
    },
  },

  methods: {
    ...mapActions({
      deleteCourseById: "course/deleteCourseById",
      updateCourseStatus: "course/updateCourseStatus",
      loadProfileInformation: "user/loadProfileInformation",
    }),

    ...mapMutations({
      setNotification: "system/SET_NOTIFICATION",
      setCourseInformation: "course/SET_COURSE_INFORMATION",
    }),

    getCourseStatusInfo,

    closeMoreActions() {
      this.show_more_actions = false;
    },

    courseAction(action) {
      switch (action) {
        case "preview":
          this.show_preview = true;
          break;
        case "cancelModeration":
          this.cancelModeration();
          break;
        case "deleteCourse":
          this.show_delete_modal = true;
          break;
        case "shareCourse":
          this.show_share_modal = true;
          break;
        case "hideCourse":
          this.show_hide_modal = true;
      }
    },

    deleteCourse() {
      if (!this.button_loader) {
        this.button_loader = true;

        this.deleteCourseById(this.course.id)
          .then(() => {
            this.setNotification({ type: "success", name: "Курс удален" });
            this.$router.push({ name: "CoursesView" });
          })
          .catch((error) => {
            this.setNotification({
              type: "error",
              name: "Что-то пошло не так",
              description: error.response.data.message,
            });
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },

    copyCourseLink() {
      navigator.clipboard.writeText(this.courseShareLink).then(() => {
        this.setNotification({
          type: "success",
          name: "Ссылка скопирована",
        });

        this.show_share_modal = false;
      });
    },

    publishCourse() {
      if (!this.user.partner.is_registered) {
        this.show_legal_modal = true;
        return;
      }

      this.show_moderation_modal = true;
    },

    submitCourseForModeration() {
      if (!this.button_loader) {
        this.button_loader = true;

        this.updateCourseStatus({
          id: this.$route.params.id,
          status: "planned",
        })
          .then(() => {
            this.setNotification({ type: "success", name: "Курс опубликован" });
            this.show_moderation_modal = false;
          })
          .catch((error) => {
            this.setNotification({
              type: "error",
              name: "Что-то пошло не так",
              description: error.response.data.message,
            });
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },

    hideCourse() {
      if (!this.button_loader) {
        this.button_loader = true;

        this.updateCourseStatus({
          id: this.$route.params.id,
          status: "hidden",
        })
          .then(() => {
            this.setNotification({ type: "success", name: "Курс скрыт" });
            this.show_hide_modal = false;
          })
          .catch((error) => {
            this.setNotification({
              type: "error",
              name: "Что-то пошло не так",
              description: error.response.data.message,
            });
          })
          .finally(() => {
            this.button_loader = false;
          });
      }
    },

    cancelModeration() {
      this.updateCourseStatus({
        id: this.$route.params.id,
        status: "hidden",
      })
        .then(() => {
          this.setNotification({
            type: "success",
            name: "Курс отозван с модерации",
          });
        })
        .catch((error) => {
          this.setNotification({
            type: "error",
            name: "Что-то пошло не так",
            description: error.response.data.message,
          });
        })
        .finally(() => {
          this.show_course_actions = false;
        });
    },
  },
};
</script>

<template>
  <CoursePagePreview v-if="show_preview" @close="show_preview = false" />

  <AppModal
    size="medium"
    v-if="show_delete_modal"
    @close="show_delete_modal = false"
  >
    <div class="default-modal">
      <h3>Удаление курса</h3>
      <p>Вы действительно хотите удалить курс?</p>

      <div class="default-modal__actions">
        <button
          class="button button_large"
          :disabled="button_loader"
          @click="deleteCourse"
        >
          Удалить
        </button>

        <button
          class="button button_large button_secondary"
          @click="show_delete_modal = false"
        >
          Отмена
        </button>
      </div>
    </div>
  </AppModal>

  <AppModal
    size="medium"
    v-if="show_hide_modal"
    @close="show_hide_modal = false"
  >
    <div class="default-modal">
      <h3>Скрыть курс</h3>
      <p>Вы действительно хотите скрыть курс?</p>

      <div class="default-modal__actions">
        <button
          class="button button_large"
          :disabled="button_loader"
          @click="hideCourse"
        >
          Скрыть
        </button>

        <button
          class="button button_large button_secondary"
          @click="show_hide_modal = false"
        >
          Отмена
        </button>
      </div>
    </div>
  </AppModal>

  <AppModal
    size="medium"
    v-if="show_legal_modal"
    @close="show_legal_modal = false"
  >
    <div class="default-modal">
      <h3>Публикация недоступна</h3>
      <p>
        Для публикации курса вам необходимо полностью заполнить юридические
        данные
      </p>

      <div class="default-modal__actions">
        <router-link
          :to="{ name: 'ProfileEdit', hash: '#legal' }"
          class="button button_large"
        >
          Заполнить данные
        </router-link>

        <button
          class="button button_large button_secondary"
          @click="show_legal_modal = false"
        >
          Отмена
        </button>
      </div>
    </div>
  </AppModal>

  <AppModal
    size="medium"
    v-if="show_moderation_modal"
    @close="show_moderation_modal = false"
  >
    <div class="default-modal">
      <h3>Публикация курса</h3>
      <p>
        Перед публикацией курса необходимо пройти модерацию. В процессе
        модерации редактирование курса будет недоступно. Хотите отправить курс
        на модерацию?
      </p>

      <div class="default-modal__actions">
        <button
          class="button button_large"
          :disabled="button_loader"
          @click="submitCourseForModeration"
        >
          Отправить
        </button>

        <button
          class="button button_large button_secondary"
          @click="show_moderation_modal = false"
        >
          Отмена
        </button>
      </div>
    </div>
  </AppModal>

  <AppModal
    size="medium"
    v-if="show_share_modal"
    @close="show_share_modal = false"
  >
    <div class="default-modal">
      <h3>Поделиться курсом</h3>

      <div class="course-share">
        <AppInput id="share" v-model="courseShareLink" readonly />

        <button class="button button_large" @click="copyCourseLink">
          <i class="icon icon-copy" />Копировать
        </button>
      </div>
    </div>
  </AppModal>

  <CourseActionsDrawer
    v-if="show_mobile_action && windowWidth < 1024"
    :actions="courseActions"
    @close="show_mobile_action = false"
    @action="courseAction"
  />

  <header class="course-header">
    <div class="course-header__left">
      <router-link
        :to="{ name: 'CoursesView' }"
        class="button-icon button-icon_medium button-icon_light"
      >
        <i class="icon icon-arrow-long-left" />
      </router-link>

      <h3>{{ course.name }}</h3>

      <span
        class="label"
        :class="[
          `label_course-${course.status}`,
          { label_small: windowWidth < 1240 },
        ]"
      >
        <i
          class="icon"
          :class="`icon-${getCourseStatusInfo(course.status).icon}`"
        />
        {{ getCourseStatusInfo(course.status).name }}
      </span>
    </div>

    <div class="course-header__right">
      <nav>
        <button @click="show_preview = true">Предпросмотр</button>

        <button @click="publishCourse" v-if="courseChangesAvailable">
          Опубликовать
        </button>

        <router-link :to="{ name: 'CourseSettings' }">Настройки</router-link>

        <div class="more" v-click-outside="closeMoreActions">
          <button
            class="more__button"
            :class="{ more__button_active: show_more_actions }"
            @click="show_more_actions = !show_more_actions"
          >
            Еще <i class="icon icon-short-arrow-down" />
          </button>

          <div class="more__dropdown" v-if="show_more_actions">
            <button
              v-for="action in courseActions"
              :key="action.id"
              :class="action.class"
              @click="courseAction(action.id)"
            >
              <i class="icon" :class="`icon-${action.icon}`" />
              {{ action.name }}
            </button>
          </div>
        </div>
      </nav>

      <div class="course-header__right__actions">
        <button><i class="icon icon-faq" /></button>
        <router-link :to="{ name: 'CourseSettings' }">
          <i class="icon icon-settings" />
        </router-link>
        <button @click="show_mobile_action = true">
          <i class="icon icon-more" />
        </button>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.course-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 0 ($base-space * 4);
  border-bottom: 1px solid #eaecf0;
  background-color: $background-light;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1023px) {
    padding: ($base-space * 3) ($base-space * 8);
  }

  @media (max-width: 767px) {
    padding: ($base-space * 3) ($base-space * 6);
  }

  @media (max-width: 424px) {
    padding: ($base-space * 3) ($base-space * 5);
  }

  @media (max-width: 374px) {
    padding: ($base-space * 3) ($base-space * 4);
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $base-space * 4;

    @media (max-width: 1023px) {
      gap: $base-space * 2;
    }

    > h3 {
      @include m-text-medium();
      color: $text-dark-primary;
      @include max-stroke(1);
      max-width: 220px;

      @media (max-width: 1023px) {
        max-width: 120px;
      }
    }

    > .label {
      @media (max-width: 1023px) {
        display: none;
      }
    }
  }

  &__right {
    > nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: $base-space;

      @media (max-width: 1023px) {
        display: none;
      }

      > button,
      a {
        padding: ($base-space * 7) ($base-space * 4);
        color: $text-dark-second;
        transition: ease-out 0.05s;
        @include m-text-regular();

        > i {
          width: 24px;
          height: 24px;
          background-color: $text-dark-second;
        }

        &:hover {
          background-color: $background-ultralight-accent;
          color: $text-accent;

          > i {
            background-color: $text-accent;
          }
        }

        &:active {
          background-color: $background-light-accent;
          color: $text-accent;

          > i {
            background-color: $text-accent;
          }
        }
      }
    }

    &__actions {
      display: none;

      @media (max-width: 1023px) {
        display: flex;
        grid-gap: $base-space * 3;
        justify-content: flex-end;
        align-items: center;
      }

      > a,
      button {
        width: 28px;
        height: 28px;

        > i {
          width: 28px;
          height: 28px;
          background-color: $text-dark-third;
        }
      }
    }
  }
}

.more {
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: $base-space;

    padding: ($base-space * 7) ($base-space * 4);
    color: $text-dark-second;
    transition: ease-out 0.05s;
    @include m-text-regular();

    > i {
      width: 24px;
      height: 24px;
      background-color: $text-dark-second;
      transition: ease-out 0.05s;
    }

    &:hover {
      background-color: $background-ultralight-accent;
      color: $text-accent;

      > i {
        background-color: $text-accent;
      }
    }

    &:active {
      background-color: $background-light-accent;
      color: $text-accent;

      > i {
        background-color: $text-accent;
      }
    }

    &_active {
      background-color: $background-light-accent;
      color: $text-accent;

      > i {
        background-color: $text-accent;
        transform: rotate(180deg);
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 260px;
    @include main-shadow();
    border-radius: 12px;
    z-index: 3;
    background: $background-light;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;

    > button {
      padding: ($base-space * 5);
      color: $text-dark-primary;
      @include m-text-regular();
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $base-space * 2;
      width: 100%;

      &:not(:last-of-type) {
        border-bottom: 1px solid $stroke-fourth;
      }

      > i {
        width: 24px;
        height: 24px;
        background-color: $text-dark-primary;
      }
    }
  }
}

.course-share {
  margin: ($base-space * 8) 0 0;
  width: 650px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $base-space * 3;

  @media (max-width: 1023px) {
    width: 100%;
    flex-flow: column;
    gap: $base-space * 2;
  }

  > button {
    @media (max-width: 1023px) {
      width: 100%;
    }

    > i {
      width: 24px;
      height: 24px;
      background-color: $text-light-primary;
    }
  }
}
</style>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";

export default defineComponent({
  name: "PanelMobileMenu",

  props: {
    navigation: {
      type: Array,
    },
  },

  computed: {
    ...mapState({
      windowWidth: (state) => state.system.window_width,
    }),
  },

  watch: {
    $route() {
      this.$emit("close");
    },

    windowWidth(newValue) {
      if (newValue > 1023) {
        this.$emit("close");
      }
    },
  },
});
</script>

<template>
  <aside class="mobile-menu">
    <div class="mobile-menu__wrapper">
      <header>
        <img src="../assets/images/logo-business.svg" alt="logotype" />

        <button @click="$emit('close')">
          <i class="icon icon-close" />
        </button>
      </header>

      <div class="mobile-menu__content">
        <h3>Навигация</h3>

        <nav>
          <router-link
            :to="{ name: item.name }"
            v-for="item in navigation"
            :key="item.name"
          >
            {{ item.title }}
          </router-link>
        </nav>
      </div>
    </div>
  </aside>
</template>

<style scoped lang="scss">
.mobile-menu {
  z-index: 50;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: $background-main-overlay;

  &__wrapper {
    padding: ($base-space * 5);
    position: relative;
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;
    background-color: $background-light;
    max-width: 425px;

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > img {
        width: 96px;
      }

      > button {
        width: 36px;
        height: 36px;

        > i {
          width: 36px;
          height: 36px;
          background: $text-dark-third;
        }
      }
    }

    > nav {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-flow: column;
      margin: ($base-space * 15) 0 0;

      > a {
        @include xl-text-regular();
        color: $text-dark-second;

        &.router-link-active {
          color: $text-accent;
        }
      }
    }
  }

  &__content {
    padding: ($base-space * 12) 0;

    > h3 {
      @include h1();
    }

    > nav {
      margin: ($base-space * 8) 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-flow: column;

      > a,
      span {
        @include xl-text-regular();
        color: $text-dark-second;
        padding: ($base-space * 2) 0;

        &.router-link-active {
          color: $text-accent;
        }
      }
    }
  }
}
</style>

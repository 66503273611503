<script>
import { defineComponent } from "vue";
import AppModal from "@/components/Ui/AppModal.vue";
import AppInput from "@/components/Ui/AppInput.vue";
import { helpers, required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { mapActions } from "vuex";
import { LESSON_TYPES } from "@/package/const/statuses";

export default defineComponent({
  name: "CreateCourseComponentModal",

  components: { AppInput, AppModal },

  props: {
    type: {
      type: String,
      default: "component",
    },
    index: {
      type: Number,
    },
    parentId: {
      type: Number,
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  emits: ["close"],

  data() {
    return {
      component: {
        name: "",
        type: "lesson",
      },

      button_loader: false,

      components_types: "",
    };
  },

  validations() {
    return {
      component: {
        name: {
          required: helpers.withMessage("Заполните поле", required),
          maxLength: helpers.withMessage(
            "Превышен лимит символов",
            maxLength(150)
          ),
        },
      },
    };
  },

  methods: {
    ...mapActions({
      addCourseUnit: "course/addCourseUnit",
    }),

    submitForm() {
      this.v$.$validate();

      if (!this.button_loader && !this.v$.$error) {
        this.button_loader = true;

        this.addCourseUnit({
          course_id: this.$route.params.id,
          data: {
            name: this.component.name,
            type: this.component.type,
            parent_id: this.parentId ? this.parentId : null,
            unit_position: this.index,
          },
        }).finally(() => {
          this.button_loader = false;
        });
        this.$emit("close");
      }
    },
  },

  beforeMount() {
    this.components_types = structuredClone(LESSON_TYPES);

    if (this.type !== "component") {
      this.components_types.pop();
    }
  },
});
</script>

<template>
  <AppModal @close="$emit('close')" size="medium">
    <div class="default-modal create-component">
      <h3>Создать компонент</h3>

      <form @submit.prevent="submitForm">
        <AppInput
          id="name"
          label="Название компонента"
          required
          :maxlength="150"
          size="medium"
          v-model="component.name"
          :error="v$.component.name?.$errors[0]?.$message"
        />

        <div class="components-types">
          <label
            :for="type.id"
            v-for="type in components_types"
            :key="type.id"
            class="component-type"
            :class="{ 'component-type_active': type.id === component.type }"
          >
            <input
              type="radio"
              v-model="component.type"
              :value="type.id"
              :id="type.id"
            />

            <img
              :src="require(`../../assets/images/course-types/${type.id}.svg`)"
              :alt="type.name"
            />

            <span>{{ type.name }}</span>
            <span>{{ type.description }}</span>
          </label>
        </div>

        <div class="create-component__actions">
          <button
            class="button button_large"
            type="submit"
            :disabled="button_loader"
          >
            Создать
          </button>

          <button
            type="button"
            class="button button_secondary button_large"
            @click="$emit('close')"
          >
            Отмена
          </button>
        </div>
      </form>
    </div>
  </AppModal>
</template>

<style scoped lang="scss">
.create-component {
  width: 794px;

  @media (max-width: 1023px) {
    width: 100%;
    overflow: auto;
    max-height: 82vh;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  > form {
    margin: ($base-space * 8) 0 0;

    @media (max-width: 1023px) {
      margin: ($base-space * 6) 0 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $base-space * 3;
    margin: ($base-space * 8) 0 0;

    @media (max-width: 1023px) {
      margin: ($base-space * 6) 0 0;
    }

    @media (max-width: 767px) {
      flex-flow: column;
      align-items: flex-start;
      gap: $base-space * 2;

      > button {
        width: 100%;
      }
    }
  }
}

.components-types {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: $base-space * 4;
  margin: ($base-space * 6) 0 0;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: $base-space * 3;
  }
}

.component-type {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 16px;
  background-color: $background-ultralight-gray;
  padding: $base-space * 8;
  border: 1px solid $background-ultralight-gray;
  cursor: pointer;
  position: relative;
  transition: ease-in 0.05s;

  @media (max-width: 1023px) {
    padding: $base-space * 6;
  }

  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-areas: "icon title" "icon description";
    align-items: start;
    gap: ($base-space * 2) ($base-space * 3);
    padding: $base-space * 5;
  }

  &_active {
    border-color: $stroke-accent;
    background-color: $background-light-accent;

    &:after {
      content: "";
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      mask: url("@/assets/icons/done.svg") 0 / cover no-repeat;
      background: $text-accent;

      @media (max-width: 767px) {
        width: 24px;
        height: 24px;
      }
    }
  }

  > input {
    display: none;
  }

  > img {
    width: 52px;
    height: auto;
    grid-area: icon;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  > span {
    display: block;
    &:first-of-type {
      @include h6();
      color: $text-dark-primary;
      margin: calc($base-space * 5) 0 0;
      grid-area: title;

      @media (max-width: 767px) {
        margin: 0;
      }
    }
    &:last-of-type {
      @include s-text-regular();
      color: $text-dark-second;
      margin: calc($base-space * 2) 0 0;
      grid-area: description;

      @media (max-width: 767px) {
        margin: 0;
      }
    }
  }
}
</style>

export default function wordDeclension(num, declensions) {
  const cases = [2, 0, 1, 1, 1, 2];
  if (num % 100 > 4 && num % 100 < 20) return declensions[2];
  return declensions[cases[num % 10 < 5 ? num % 10 : 5]];
}

export function ageDeclension(amount, words) {
  let count = amount % 100;
  if (count >= 5 && count <= 20) {
    return amount + words[0];
  }

  count = amount % 10;
  if (count === 1) {
    return amount + words[1];
  } else if (count > 1 && count < 5) {
    return amount + words[2];
  } else {
    return amount + words[0];
  }
}
